export enum GlobalFeature {
  EDIT_PROJECT = 'edit-project',
  ADD_PAGE = 'add-page',
  EDIT_PAGE = 'edit-page',
  SHARE = 'share',
  CREATE_LAYER = 'create-layer',
  DELETE_PAGE = 'delete-page',
  BACKUP = 'backup',
  RESTORE = 'restore',
  EXPORT = 'export',
}

export enum TakeoffFeature {
  TOOLBAR = 'toolbar',
  SIDEBAR = 'sidebar',
  SCALE = 'scale',
}
