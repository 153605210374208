/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ActionIcon, AppShell, Avatar, Box, Button, Text, Tooltip } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Toolbar } from 'components/toolbar/toolbar'
import { ProvideDrawing } from 'hooks/useDrawing'
import { AvatarWithUserMenu } from 'components/avatar/avatar-with-user-menu'
import { useState } from 'react'
import { globalStore } from 'store/store'
import { getInitials } from 'utils/initials'
import { DEFAULT_LEFT_SIDEBAR_WIDTH } from 'components/toolbar/types'
import { useTranslation } from 'react-i18next'
import { ShareProjectModal } from 'components/modals/share-project.modal'
import { useStore } from 'zustand'
import { useGetProject } from 'api/query/project'
import { useGetPage } from 'api/query/page'
import { ScaleToolbar } from 'components/toolbar/scale.toolbar'
import { Modals } from 'components/modals/modals'
import { ProvideWebsocket } from 'hooks/websocket/useWebsocket'
import { MyPermission } from 'components/permission/my-permission'

export const TakeoffLayout = () => {
  const navigate = useNavigate()
  const { projectId, pageId } = useParams()
  const { t } = useTranslation()

  const { data: project } = useGetProject(projectId)
  const { data: page } = useGetPage(projectId!, pageId!)

  const [shareProjectModalOpened, setShareProjectModalOpened] = useState(false)
  const connectedUsers = useStore(globalStore, (state) => state.usersConnected)

  const url = project?.organizationId
    ? `/app/organization-projects/${project.id}`
    : project?.isSharedProject
      ? `/app/shared-projects/${project?.id}`
      : `/app/projects/${project?.id}`

  return (
    <ProvideWebsocket>
      <ProvideDrawing>
        <AppShell
          padding={0}
          header={{
            height: 70,
          }}
          aside={{
            breakpoint: 'sm',
            width: 30,
          }}
          navbar={{
            breakpoint: 'sm',
            width: 55,
          }}
          styles={(theme) => ({
            main: {
              paddingTop: 0,
              backgroundColor: theme.colors.gray[3],
              display: 'flex',
              touchAction: 'none',
              position: 'relative',
            },
            navbar: {
              display: 'flex',
              flexDirection: 'column',
            },
          })}
        >
          <AppShell.Header zIndex={110} p="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box display="flex" sx={{ alignItems: 'center' }}>
              <ActionIcon variant="transparent" c="gray" onClick={() => navigate(url)}>
                <IconArrowLeft />
              </ActionIcon>
              <Box display="flex" ml={20} sx={{ alignItems: 'center' }}>
                <Text lh="24px" fz={20} fw={600} sx={{ letterSpacing: '0.5px' }}>
                  {project?.name}
                </Text>
                <Text lh="24px" c="gray" ml={10} mr={10} fz={20}>
                  /
                </Text>
                <Text lh="24px" fz={20} sx={{ letterSpacing: '0.5px' }}>
                  {page?.name}
                </Text>
              </Box>
            </Box>
            <Box display="flex" sx={{ alignItems: 'center' }}>
              <MyPermission />

              <Avatar.Group spacing="sm" mr={10}>
                {connectedUsers.map((user) => (
                  <Tooltip label={user} key={user} withArrow>
                    <Avatar key={user} radius="xl">
                      {getInitials(user)}
                    </Avatar>
                  </Tooltip>
                ))}
              </Avatar.Group>
              {project?.isOwner && (
                <Button radius="xxs" size="sm" mr={10} onClick={() => setShareProjectModalOpened(true)}>
                  {t('Project.ShareCurrentProject')}
                </Button>
              )}

              <AvatarWithUserMenu />
            </Box>
          </AppShell.Header>
          <AppShell.Navbar>
            <Toolbar />
          </AppShell.Navbar>
          <AppShell.Main>
            <Outlet />
            <ScaleToolbar />
          </AppShell.Main>

          <Modals />
        </AppShell>
        {project?.isOwner && (
          <ShareProjectModal project={project} open={shareProjectModalOpened} toggle={() => setShareProjectModalOpened(false)} />
        )}
      </ProvideDrawing>
    </ProvideWebsocket>
  )
}
