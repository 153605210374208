/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { ActionIcon, Alert, Anchor, Box, Button, Checkbox, Flex, Progress, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { IconPencil, IconTrash, IconInfoCircle, IconCloudCog, IconEdit } from '@tabler/icons-react'
import { Page, PageDto } from 'api/dto'
import { usePollingQuery } from 'hooks/usePollingQuery'
import { PageTitle } from 'components/title/page-title'
import { formatMoment } from 'utils/date'
import { ProjectProgressBar } from 'components/progressbar/progress-bar'
import { ColorScheme } from 'theme/theme'
import { PageTile } from 'components/tile/page-tile'
import { AugmentedTable } from 'components/table/augmented-table'
import { UploadPdf } from 'components/dropzone/dropzone'
import { fileAxios } from 'api/file/file-axios'
import { Loading } from 'components/loading/loading'
import { useDisclosure } from '@mantine/hooks'
import { ShareProjectModal } from 'components/modals/share-project.modal'
import Logo from 'assets/img/ttlogo.svg?react'
import { EditPage } from 'components/modals/edit-page.modal'
import { DeleteModal } from 'components/modals/delete.modal'
import { AxiosProgressEvent } from 'axios'
import { listPages, useDeletePage } from 'api/query/page'
import { useGetProject, useGetProjectSignedUrl } from 'api/query/project'
import { ColumnDef } from '@tanstack/table-core'
import dayjs from 'dayjs'
import { Export } from 'components/section/export'
import { showNotification } from '@mantine/notifications'
import { ProjectService } from 'api/services/project-service'
import { GlobalFeature } from 'api/dto/feature'
import { EditProject } from 'components/modals/edit-project.modal'

const useProjectStyles = createStyles(() => ({
  actions: {
    '.mantine-ThemeIcon-root': {
      cursor: 'pointer',
    },
  },
}))

export function Pages() {
  const { classes } = useProjectStyles()
  const [searchParams] = useSearchParams()
  const { projectId } = useParams()
  const { t } = useTranslation()

  const { data: project, isLoading: isLoadingProject, isSuccess: isSuccessProject } = useGetProject(projectId)

  const {
    data: pages,
    isLoading,
    numberOfTimePolled,
    startedPolling,
    canceledPolling,
    startPolling,
  } = usePollingQuery<PageDto[]>({
    queryFn: () => {
      return listPages(projectId!)
    },
    queryKey: ['projects', projectId!, 'pages'],
    select: useCallback((response) => response.map((p) => new Page({ ...p, projectId: projectId! })), [projectId]),
    successCondition: (data) => {
      const state = data?.every((p) => p.isInReadyState)
      if (state) {
        searchParams.delete('polling')
      }
      return state
    },
    hasToFetchAfterAdd: searchParams.get('polling') === 'true',
  })
  const { mutateAsync: getUploadSignedUrl } = useGetProjectSignedUrl()
  const { mutateAsync: deletePage } = useDeletePage()
  const [selectedPage, setSelectedPage] = useState<PageDto | undefined>()
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [editModalOpened, setEditModalOpened] = useState(false)
  const [shareProjectModalOpened, setShareProjectModalOpened] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [opened, { toggle }] = useDisclosure(false)
  const [uploadPercentageCompleted, setUploadPercentageCompleted] = useState<number | null>(null)
  const [selectedExportPages, setSelectedExportPages] = useState<string[]>([])
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const sortedPages = useMemo(() => (pages as Page[] | undefined)?.sort((a, b) => a.order - b.order), [pages])

  const [editProjectModalOpened, { toggle: toggleEditProject }] = useDisclosure(false)

  const tableHeight = selectedExportPages.length
    ? `calc(100% - ${opened ? '450px' : '200px'} )`
    : `calc(100% - ${opened ? '375px' : '140px'} )`

  const onCheck = useCallback((page: PageDto) => {
    setSelectedExportPages((prev) => {
      if (prev.includes(page.id)) {
        return prev.filter((p) => p !== page.id)
      }
      return [...prev, page.id]
    })
  }, [])

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 100))
    setUploadPercentageCompleted(percentCompleted)
  }

  const addFile = useCallback(
    async (file: File) => {
      setIsUploading(true)
      if (file && projectId) {
        //TODO notification

        const response = await getUploadSignedUrl({ projectId, fileName: file.name })

        if (response) {
          await fileAxios.put(response.data, file, {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'content-type': file.type,
            },
            onUploadProgress,
          })

          setTimeout(() => startPolling(), 1200)
        }
      }
      setUploadPercentageCompleted(null)
      setIsUploading(false)
    },
    [projectId, getUploadSignedUrl, startPolling],
  )

  const onDelete = async () => {
    if (!selectedPage || !projectId) {
      return
    }

    await deletePage({ projectId, pageId: selectedPage.id })

    setSelectedPage(undefined)
    setDeleteModalOpened(false)
  }

  const columns: ColumnDef<Page>[] = useMemo(
    () =>
      [
        {
          id: 'export',
          header: t('Page.Export'),
          width: '10%',
          enableSorting: false,
          cell: (p) => {
            const page = p.row.original
            return <Checkbox checked={selectedExportPages?.includes(page.id)} onChange={() => onCheck?.(page)} />
          },
        },
        {
          id: 'name',
          header: t('Page.Name'),
          width: 'auto',
          enableSorting: false,
          cell: (p) => {
            const page = p.row.original
            if (page.isInProgressState) {
              return (
                <Text size="sm" color="gray">
                  {page.name}
                </Text>
              )
            }

            const layerId = page.getOriginalLayerId()
            const link = `/board/${page.projectId}/pages/${page.id}/layers/${layerId}`
            return (
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              <Anchor display="flex" size="sm" href={projectId ? link : ''}>
                {page.hasTakeoff && (
                  <Tooltip label={t('Page.HasTakeoff')} withArrow>
                    <Box mr={10}>
                      <Logo width={15} height={15} style={{ fill: 'rgb(35,149,255)' }} />
                    </Box>
                  </Tooltip>
                )}
                <Text size="sm" color="black">
                  {page.name}
                </Text>
              </Anchor>
            )
          },
        },
        {
          id: 'description',
          width: '30%',
          header: t('Page.Description'),
          enableSorting: false,
          accessor: (p) => p.description,
          cell: (p: any) => {
            return (
              <Text size="sm" color="gray">
                {p.cell.value}
              </Text>
            )
          },
        },
        {
          id: 'lastModifiedAt',
          width: '10%',
          header: t('Page.UpdatedAt'),
          accessorKey: 'lastModifiedAt',
          accessorFn: (p) => formatMoment(p.lastModifiedAt || ''),
          enableSorting: false,
          cell: (p) => {
            return (
              <Text size="xs" color="gray">
                {p.cell.getValue() as any}
              </Text>
            )
          },
        },
        {
          id: 'createdAt',
          width: '10%',
          enableSorting: false,
          header: t('Page.CreatedAt'),
          accessorKey: 'createdAt',
          accessorFn: (p) => dayjs(p.createdAt || '').format('YYYY-MM-DD HH:mm'),
          cell: (p) => {
            return (
              <Text size="xs" color="gray">
                {p.cell.getValue() as any}
              </Text>
            )
          },
        },
        {
          id: 'status',
          width: '10%',
          header: t('Page.Status'),
          enableSorting: false,
          accessorFn: (p) => (p.status ? t(`Page.PageCreationStatus.${p.status}`) : ''),
          cell: (p) => {
            return (
              <Text size="sm" color="gray">
                {p.cell.getValue() as any}
              </Text>
            )
          },
        },
        {
          id: 'actions',
          header: t('Page.Actions'),
          cell: (p) =>
            projectId ? (
              <Box className={classes.actions}>
                <ThemeIcon
                  size="md"
                  color="gray"
                  onClick={() => {
                    setSelectedPage(p.row.original)
                    setEditModalOpened(true)
                  }}
                >
                  <IconPencil size={20} />
                </ThemeIcon>

                <ThemeIcon
                  ml={10}
                  size="md"
                  color="dark"
                  onClick={() => {
                    setSelectedPage(p.row.original)
                    setDeleteModalOpened(true)
                  }}
                >
                  <IconTrash size={20} />
                </ThemeIcon>
              </Box>
            ) : null,
        },
      ] as ColumnDef<Page>[],
    [t, classes, projectId, selectedExportPages, onCheck],
  )

  if (isLoading || isLoadingProject) {
    return <Loading height="100%" />
  }

  if (!isSuccessProject || !pages) {
    return null
  }

  return (
    <>
      <Box h={'100%'}>
        <Box
          display="flex"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Flex align="center">
              <PageTitle title={project.name} />
              <ActionIcon ml={10} variant="transparent" c="gray" onClick={toggleEditProject}>
                <IconEdit />
              </ActionIcon>
            </Flex>

            <Box mt={15} w={400} display="flex" sx={{ flexFlow: 'row', alignItems: 'center' }}>
              <ProjectProgressBar totalPages={project.numberOfPages} filledPages={project.numberOfPagesWithTakeoff} />
              <Text w={400} ml={30} size="xs" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][5] })}>
                {project.numberOfPagesWithTakeoff} {t('Project.NumberOfPagesWithTakeoff')}
              </Text>
            </Box>
          </Box>
          <Box>
            {project.isOwner && (
              <>
                {project.hasAccess(GlobalFeature.EXPORT) && (
                  <Button
                    size="sm"
                    radius="xxs"
                    loading={isLoadingExport}
                    onClick={async () => {
                      setIsLoadingExport(true)
                      try {
                        const res = await ProjectService.generateProjectReport(project?.id || '', undefined, 'csv')
                        const csv = res.data

                        if (!csv) {
                          showNotification({
                            title: t('Project.CSVExportFailed'),
                            message: t('Project.CSVExportFailedMessage'),
                            color: 'red',
                          })
                          setIsLoadingExport(false)
                          return
                        }

                        const linkSource = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
                        const downloadLink = document.createElement('a')
                        const projectName = project?.name || 'NA'
                        const fileName = `${projectName}-${dayjs().format('YYYY-MM-DD')}.csv`
                        downloadLink.href = linkSource
                        downloadLink.download = fileName
                        downloadLink.click()
                        setIsLoadingExport(false)
                      } catch (error) {
                        setIsLoadingExport(false)
                        return
                      }
                    }}
                  >
                    {t('Project.ExportProjectCSV')}
                  </Button>
                )}

                <Button ml={10} size="sm" radius="xxs" onClick={() => setShareProjectModalOpened(true)}>
                  {t('Project.ShareCurrentProject')}
                </Button>
                <ShareProjectModal project={project} open={shareProjectModalOpened} toggle={() => setShareProjectModalOpened(false)} />
              </>
            )}
          </Box>
        </Box>

        {!startedPolling && (
          <Box mt={30}>
            <UploadPdf
              withCollapse
              opened={opened}
              toggle={toggle}
              onDrop={(files) => addFile(files[0])}
              loading={isUploading}
              withLoading
            />
          </Box>
        )}

        {uploadPercentageCompleted && (
          <Box mb={10} mt={10} display="flex" sx={{ flexFlow: 'column', alignItems: 'center' }}>
            <Text size="sm" fw={400}>
              Uploading file... {uploadPercentageCompleted}% completed
            </Text>
            <Progress w="100%" striped animated value={uploadPercentageCompleted} color="teal" size="sm" mt={10} mb={10} />
          </Box>
        )}

        {startedPolling && !canceledPolling && (
          <Box mt={20}>
            <Alert icon={<IconInfoCircle size="1rem" />} color="yellow" radius="md" withCloseButton>
              {numberOfTimePolled < 25
                ? 'You have just uploaded a PDF file. Please wait a few seconds before you can start taking off.'
                : 'The PDF uploaded is taking more time than usual to convert. Please wait until the process is finished.'}
            </Alert>
          </Box>
        )}

        {sortedPages && sortedPages.length > 0 && (
          <Box mt={30} sx={{ overflow: 'auto', maxHeight: tableHeight }}>
            <AugmentedTable<Page>
              Tile={PageTile}
              columns={columns as any}
              data={sortedPages}
              canEdit
              checked={selectedExportPages}
              onCheck={onCheck}
              showMenu={true}
            />
          </Box>
        )}
      </Box>
      <EditPage opened={editModalOpened} toggle={() => setEditModalOpened(false)} page={selectedPage} />
      <DeleteModal opened={deleteModalOpened} toggle={() => setDeleteModalOpened(false)} deleteFunction={onDelete} />
      {selectedExportPages.length > 0 && project.hasAccess(GlobalFeature.EXPORT) && (
        <Export project={project} pages={selectedExportPages} reset={() => setSelectedExportPages([])} />
      )}

      <EditProject project={project} open={editProjectModalOpened} toggle={toggleEditProject} />
    </>
  )
}
